.flexGrow {
  flex-grow: 1;
}

.buttonStyle {
  padding: 0;
  display: flex;
  flex-direction: row;
}

.logoStyle {
  margin-right: 16px;
  height: 40px;
}
